import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import GameProducer from "../components/GameProducer";
import games from "../assets/social-games-lp";
import imgMystiJack from "../assets/imgs/mysti-jack-wink 1.png";
import imgMysti from "../assets/imgs/Mysti-cards2 1.png";
import imgJack from "../assets/imgs/Jack-standing 1.png";
import imgJackMobile from "../assets/imgs/jack-mobile.png";
import imgTailPink from "../assets/imgs/TailPink.png";
import imgTail from "../assets/imgs/Tail.png";
import imgTailLeft from "../assets/imgs/Tail-left.png";
import imgTailMys from "../assets/imgs/tall-left-mysti.png";
import imgTailBottom from "../assets/imgs/tail-bottom.png";
import textBanner from "../assets/imgs/Group 1859.png";
import headerImageMobile from "../assets/imgs/Mystino-home-m.jpg";
import headerLoginImage from "../assets/imgs/Mystino-home.jpg";
import headerRegisterImage from "../assets/imgs/Mystino-home.jpg";
import bannerFooter from "../assets/imgs/2019_mar_live_baccarat_environment_overview_4 1.png";
import imgFooterMysti from "../assets/imgs/Mysti-here-you-go.png";
import imgFooterJack from "../assets/imgs/Jack-sitting.png";
import imgDealer from "../assets/imgs/dealer-bombay 1.png";
import TailL2 from "../assets/imgs/Tail-l2.png";
import imgSc1 from "../assets/imgs/Sc1.png";
import imgSc2 from "../assets/imgs/Sc2.png";
import imgTextFooter from "../assets/imgs/text-banner-footer.png";
import { Helmet } from "react-helmet";
import IframeModal from "../components/modals/IframeModal";

const isMobile = window.innerWidth <= 768;

const gameProducers = [
  {
    image: require("../assets/imgs/game_producers/netent.png"),
    title: "NETENT",
    link: "/netent",
  },
  {
    image: require("../assets/imgs/game_producers/yggdrasil-gaming.png"),
    title: "Evolution Gaming",
    link: "/evolution",
  },
  {
    image: require("../assets/imgs/game_producers/jtg-logo.png"),
    title: "JTG",
    link: "/jtg",
  },
  {
    image: require("../assets/imgs/game_producers/playgo.png"),
    title: "Play'n Go",
    link: "/playgo",
  },
  {
    image: require("../assets/imgs/game_producers/microgaming.png"),
    title: "Blaze of Ra",
    link: "blazeofra",
  },
];

class HomepageLP extends React.Component {
  owlCarouselOptions = {
    items: 5,
    margin: 5,
    stagePadding: 20,
    nav: false,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
      },
      720: {
        items: 2,
        nav: true,
      },
      1000: {
        items: 5,
        nav: true,
      },
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      gameList: [],
      gameLaunchUrl: "",
      errorMessage: "",
      limit: 25,
      showLoadMoreButton: true,
      numberOfGames: 0,
      matches: window.matchMedia("(max-width: 768px)").matches,
    };

    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    let newGames = [];

    console.log(games);
    games.map((game, indexGame) => {
      if (isMobile && indexGame >= 12) {
        return true;
      }

      const gamesCanPlay = [
        "hawaiian_dream.winfast",
        "baccarat.skywind",
        "roulette_neo.relax",
        "mystino_blackjack.onetouch"
      ];
      let canPlay = false;

      if (gamesCanPlay.includes(game.id)) {
        canPlay = true;
      }

      newGames.push({
        id: game.id,
        name: game.name,
        image: game.thumbnailUrl,
        metaTitle: game.metaTitle,
        shortDescription: game.shortDescription,
        canPlay: canPlay,
        gameIframe: false,
      });

      // if (indexGame === 2) {
      //     newGames.push({
      //         id: 'Group 1896',
      //         text: '新CM公開中',
      //         class: 'banner-game',
      //         classText: 'text-iframe',
      //         gameIframe: true,
      //         urlIframe: 'https://www.youtube.com/embed/srj4edde4W8'
      //     });
      // }
      //
      // if (indexGame === 26) {
      //     newGames.push({
      //         id: 'Group 1890',
      //         text: 'たくさんのドキドキが待っているミスティーノの世界はもう訪れましたか？',
      //         class: 'banner-game2',
      //         classText: 'text-iframe2',
      //         gameIframe: true,
      //         urlIframe: 'https://www.youtube.com/embed/G6zRhgg3CGU'
      //     });
      // }
    });

    this.setState({
      gameList: newGames,
    });
  }

  loadMore = (e) => {
    e.preventDefault();

    if (this.state.limit + 31 >= this.state.gameList.length) {
      this.setState({ showLoadMoreButton: false });
    }

    this.setState({ limit: this.state.limit + 31 });
  };

  showIframe(urlIframe) {
    this.props.showIframeModal(urlIframe);
  }

  //helpere
  showGames = (playAllGames) => {
    return this.state.gameList.slice(0, this.state.limit).map((game, index) => {
      let link;
      let gameBlur = "";

      if (playAllGames !== true) {
        if (game.canPlay === true) {
          link = (
            <Link to={`/game/` + game.id} className="play2 button">
              Play
            </Link>
          );
        } else {
          if (localStorage["isReturnVisitor"]) {
            link = (
              <div className="div-homepage-register">
                <a
                  className="button-register"
                  onClick={this.props.showLoginModal}
                >
                  ログインしてプレイ
                </a>
              </div>
            );
          } else {
            link = (
              <div className="div-homepage-register">
                <a className="button-register" onClick={this.props.showModal}>
                  新規登録してプレイ
                </a>
              </div>
            );
          }

          gameBlur = "game-blur";
        }
      } else {
        link = (
          <Link to={`/game/` + game.id} className="play2 button">
            Play
          </Link>
        );
      }

      return (
        <div key={index}>
          {(playAllGames !== false || game.canPlay === true) &&
          !game.gameIframe ? (
            <div className="game__card">
              <div className="game-text">今すぐプレイ</div>
              <Link to={`/game/` + game.id} className="game-card-link">
                <img
                  className={"game__card--image " + gameBlur}
                  src={require("../assets/imgs/games_imgs/" +
                    game.id +
                    ".jpeg")}
                  alt=""
                />
                <p className="game__card--title">{game.name}</p>
              </Link>
              <p className="game__card--subtitle">{game.shortDescription}</p>
              {link}
            </div>
          ) : !game.gameIframe ? (
            <div className={"game__card"}>
              <img
                className={"game__card--image " + gameBlur}
                src={require("../assets/imgs/games_imgs/" + game.id + ".jpeg")}
                alt=""
              />
              <p className="game__card--title">{game.name}</p>
              <p className="game__card--subtitle">{game.shortDescription}</p>
              {link}
            </div>
          ) : (
            <div
              className={"game__card " + game.class}
              onClick={this.showIframe(this, game.urlIframe)}
            >
              <img
                className={"game__card--image " + gameBlur}
                src={require("../assets/imgs/games_imgs/" + game.id + ".jpeg")}
                alt=""
              />
              <p className={game.classText}>{game.text}</p>
            </div>
          )}
        </div>
      );
    });
  };

  showGameProducers = () => {
    window.getSelection().empty();
    return gameProducers.map((elem, index) => (
      <GameProducer key={index} image={elem.image} link={elem.link} />
    ));
  };

  render() {
    let contentGames = "";
    let playAllGames = false;
    let headerImage = headerRegisterImage;
    let CtaHeader = (
      <a className="button-register" onClick={this.props.showModal}>
        新規登録はこちら
      </a>
    );
    let showMoreContent = (
      <div className="text-center bg-mobile p-10">
        <a
          href="#"
          onClick={this.loadMore}
          className="slideshow__container--button"
        >
          すべてのゲームを見る
        </a>
      </div>
    );

    if (this.props.currentUser) {
      playAllGames = true;
    }

    contentGames = this.showGames(playAllGames);

    if (!this.state.showLoadMoreButton) {
      showMoreContent = "";
    }

    if (localStorage["isReturnVisitor"]) {
      headerImage = headerLoginImage;
      CtaHeader = (
        <a className="button-register" onClick={this.props.showLoginModal}>
          ログイン
        </a>
      );
    }
    if (this.state.matches) {
      headerImage = headerImageMobile;
    }

    return (
      <div className="homepage__container">
        <Helmet>
          <title>ミスティーノFREE | 無料オンラインカジノゲーム</title>
        </Helmet>

        <div className="bg-home-banner" style={{paddingTop: 20}}>
          <p className="bg-home-banner-title">カジノゲームが初めての方</p>
          <div className="home-container-cards me-3">
            <div className="home-banner-container">
              <img
                className="img-home-banner"
                src={require("../assets/imgs/homeScreenBanner/Responsible_Gaming.svg")}
              />
              <p className="scris-home-banner">責任あるゲーム</p>
              <Link to={`/responsible-gaming`}>
                <div className="button-home-banner">
                  <p>読む</p>
                </div>
              </Link>
            </div>

            <div className="home-banner-container ml-3">
              <img
                className="img-home-banner"
                src={require("../assets/imgs/homeScreenBanner/Blackjack.svg")}
              />
              <p className="scris-home-banner">ゲームの遊び方</p>
              <Link to={`/how-to-play`}>
                <div className="button-home-banner">
                  <p>学ぶ</p>
                </div>
              </Link>
            </div>
          </div>

          <img
            src={require("../assets/imgs/homeScreenBanner/Card.png")}
            className="img-home-card"
          />
          <img
            src={require("../assets/imgs/homeScreenBanner/18_symbol_ruby_250k.png")}
            className="img-home-ruby"
          />
          <img
            src={require("../assets/imgs/homeScreenBanner/16_symbol_horseshoe_250k.png")}
            className="img-home-horseShoe"
          />
          <img
            src={require("../assets/imgs/homeScreenBanner/1_dice.png")}
            className="img-home-dice"
          />
          <img
            src={require("../assets/imgs/homeScreenBanner/7.png")}
            className="img-home-7"
          />
        </div>

        <div className="container mt-4 div-head-img">
          <div className="row row-head-img">
            <div className="col-md-12 mb-2">
              <p className={"text-center font26"}>TVCM放送中</p>
            </div>
            <div className="col-md-4 text-right col-sm-6 col-xs-6">
              <img
                src={imgSc2}
                className={"cursor-pointer"}
                onClick={() =>
                  this.showIframe("https://www.youtube.com/embed/gKSeIke-VFs")
                }
                alt={"Mystino"}
              />
              <p className={"text-center"}>ルーレット編</p>
            </div>
            <div className="col-md-4 text-left  col-sm-6 col-xs-6">
              <img
                src={imgSc1}
                className={"cursor-pointer"}
                onClick={() =>
                  this.showIframe("https://www.youtube.com/embed/Tf7uICK_tR4")
                }
                alt={"Mystino"}
              />
              <p className={"text-center"}>スロット編</p>
            </div>
          </div>
        </div>

        <div className="game__container">{contentGames}</div>

        <div className="blur-footer"></div>
        <div className="jack-footer">
          <img className={"img-footer-jack"} src={imgFooterJack} />
          <p className={"text-jack-footer"}>
            <img src={TailL2} />
            ゲーム少なすぎる？!
          </p>
        </div>
        <div className="banner-footer text-center mb-5">
          <div className="pos-relative overflow-x-clip">
            <img className={"img-footer-dealer"} src={imgDealer} />
            <img className={"img-banner-footer"} src={bannerFooter} />
            <img className={"img-footer-mys"} src={imgFooterMysti} />
          </div>
        </div>
        <div className="img-footer">
          {/*<img src={imgMystiJack}/> <span><img src={imgTailPink}/>またお待ちしております！</span>*/}
        </div>
      </div>
    );
  }
}

export default HomepageLP;
